<template>
    <div>
        <b-row class="mb-3">
            <b-col cols="12" md="6">
                <b-card class="soft mb-5">
                    <b-form-group :label="$t('academic_year')">
                    <b-input-group>
                        {{ this.isNotNullValue(formData.semester.academic_year) }}
                    </b-input-group>
                </b-form-group>
                    <b-form-group :label="$t('period')">
                        <b-input-group>
                            {{ this.isNotNullValue(formData.semester.semester) }}
                        </b-input-group>
                    </b-form-group>
                    <b-form-group :label="$t('ombudsman')">
                        <b-input-group>
                            {{
                                this.isNotNullValue(formData.ombudsman.person.name) + " " + this.isNotNullValue(formData.ombudsman.person.surname)
                            }}
                        </b-input-group>
                    </b-form-group>
                    <b-form-group :label="$t('decision')">
                        <b-input-group>
                            {{ formData.decision_text }}
                        </b-input-group>
                    </b-form-group>
                    <b-form-group :label="$t('decision_date')">
                        <b-input-group>
                            {{ this.toLocaleDate(formData.decision_date) }}
                        </b-input-group>
                    </b-form-group>
                </b-card>
            </b-col>
            <b-col cols="12" md="6">
                <b-card class="soft mb-5">
                    <b-form-group :label="$t('punishment')">
                        <b-input-group>
                            {{ formData.punishment_text }}
                        </b-input-group>
                    </b-form-group>
                    <b-form-group :label="$t('punishment_start_date')">
                        <b-input-group>
                            {{ this.toLocaleDate(formData.punishment_start_date) }}
                        </b-input-group>
                    </b-form-group>
                    <b-form-group :label="$t('punishment_end_date')">
                        <b-input-group>
                            {{ this.toLocaleDate(formData.punishment_end_date) }}
                        </b-input-group>
                    </b-form-group>
                    <b-form-group :label="$t('stay_of_execution_decision')">
                        <b-input-group>
                            {{ formData.stay_of_execution_decision? this.$t('yes') : this.$t('no') }}
                        </b-input-group>
                    </b-form-group>
                    <b-form-group :label="$t('explanation')">
                        <b-input-group>
                            {{ this.isNotNullValue(formData.explanation) }}
                        </b-input-group>
                    </b-form-group>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>
<script>
import DisciplinesService from "@/services/DisciplinesService";
import moment from "moment";

export default {
    props: {
        formId: null
    },
    data() {
        return {
            formData: {
                punishment_text: null,
                punishment_start_date: null,
                punishment_end_date: null,
                semester: {academic_year: null, semester: null},
                decision: null,
                decision_date: null,
                ombudsman: {person: {name: null, surname: null}},
                stay_of_execution_decision: null,
                explanation: null
            },
        }
    },
    async created() {
        await this.get(this.formId)
    },
    methods: {
        get(id) {
            DisciplinesService.get(id)
                .then(response => {
                    let data = response.data.data;
                    this.formData = {
                        ...data,
                    }
                })
                .catch(e => {
                    this.showErrors(e)
                })
        },
    }
}
</script>
