<template>
    <div class="d-flex flex-column vh-100">
        <div class="container py-5 my-0 my-sm-5 px-4 not-layout">
            <div class="row">
                <div class="col-12 col-sm-12 col-md-12 col-lg-10 col-xl-8 offset-lg-1 offset-xl-2">
                    <div class="row mb-2">
                        <div class="col-12">
                            <div class="row align-items-center mb-0 pb-5 mb-sm-2">
                                <div class="col-4 text-left">
                                    <div class="logo-wrapper"><img src="@/assets/img/berlin_logo.png"/></div>
                                </div>
                                <div class="col-4 text-center">
                                    <h4 class="mb-0">{{ $t('epayment') }}</h4>
                                </div>
                                <div class="col-4 text-right">
                                    <user-button></user-button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <b-tabs content-class="py-5 position-relative" v-model="tabIndex">
                        <div class="tabs-bottom-line"></div>
                        <b-tab active>
                            <template #title>
                                <i class="ri-profile-line top-plus-2 mr-2"></i>{{ $t('questioning') }}
                            </template>
                            <div class="px-0 px-lg-5 mt-1 mb-5 mt-md-5">
                                <div class="row px-0 px-lg-5">
                                    <div class="col-12 col-md-6 col-lg-8 offset-md-3 offset-lg-2 px-3 px-md-0 px-lg-5">
                                        <ValidationObserver ref="loginForm">
                                            <ValidationProvider name="identityNumberOrPassportNumber" rules="required" v-slot="{valid, errors}">
                                                <b-form-group :label="$t('tc_no')+' / '+$t('passport_number')">
                                                    <b-form-input type="text"
                                                                  v-model="loginForm.identityNumber"
                                                                  :state="errors[0] ? false : (valid ? true : null)">
                                                    </b-form-input>
                                                    <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                                </b-form-group>
                                                <b-button type="button" variant="primary btn-block" @click="login">
                                                    {{ $t('questioning') }}
                                                </b-button>
                                            </ValidationProvider>
                                        </ValidationObserver>
                                    </div>
                                </div>
                            </div>
<!--                            <div class="row">
                                <div class="col-12">
                                    <p class="alert alert-dark">
                                        Sevgili öğrencimiz,<br>

                                        2023-2024 Eğitim yılı için <b>01.04.2023-28.04.2023</b> tarihlerine özel kayıt yenileme ücretlerinize <a href="https://beta.bau.edu.tr/epayment/login">https://beta.bau.edu.tr/epayment/login</a> adresinden TC Kimlik numaranız ile ulaşabilirsiniz. Söz konusu ücretler sadece bu tarihler arasında geçerli olup, bu tarihten sonra yapılacak kayıt yenileme işlemlerinizde yeni dönem için belirlenen ücretler geçerli olacaktır. 2023-2024 yeni dönem kayıt ücretleriniz daha sonra ayrıca duyurulacaktır. Konuyla ilgili tüm sorularınızı BAU Öğrenci Çözüm Merkezine 444 28 64 telefon numarasından veya help.bau.edu.tr adresinden bize iletebilirsiniz.
                                    </p>
                                </div>
                            </div>-->
                            <h6 class="text-uppercase mb-3">{{ $t('installment_options') }}</h6>
                            <b-table
                                :empty-text="$t('there_are_no_records')"
                                bordered
                                striped
                                responsive
                                show-empty
                                :fields="virtualPosFiels"
                                :items="virtualPosItems"
                                sort-by="code"
                                class="mb-4 border rounded"
                            >
                                <template #cell(code)="data">
                                    <img :src="getPosImage(data.value)" style="max-height:18px; max-width:70px">
                                </template>
                                <template #cell(name)="data">
                                    {{ data.item.name }}
                                </template>
                                <template #cell(installments)="data">
                                    {{ data.value.map(i => i + ' ' + $t('installment')).join(", ") }}
                                </template>
                                <template #cell(extra_installment)="data">
                                    {{ data.item.extra_installment ? data.item.extra_installment:'-' }}
                                </template>
                                <template #cell(total_installment)="data">
                                    {{ data.value ? data.value + ' ' + $t('installment'):'-' }}
                                </template>
                            </b-table>
                        </b-tab>
                        <b-tab :disabled="computeActiveTab">
                            <template #title>
                                <i class="ri-checkbox-multiple-line top-plus-2 mr-2"></i>
                                {{ $t('verification_method') }}
                            </template>
                            <div class="row my-1 my-md-5">
                                <div class="col-12 col-md-6 col-lg-6 offset-md-3 offset-lg-3">
                                    <div v-for="verificationOption in verificationOptions" v-if="verificationOptions">
                                        <b-form-group>
                                            <b-button :variant="verificationType==verificationOption ? 'primary':'outline-primary'"
                                                      size="lg"
                                                      class="justify-content-start"
                                                      block
                                                      @click="verificationType=verificationOption;scroll_right()">
                                                <span class="d-flex"><i class="ri-information-line mr-2"></i>
                                                    {{ $t('verification_' + verificationOption) }}
                                                </span>
                                            </b-button>
                                        </b-form-group>
                                    </div>
                                    <div v-else>
                                        {{ $t('verification_method_not_found') }}
                                    </div>
                                </div>
                            </div>
                        </b-tab>
                        <b-tab :disabled="computeActiveTab">
                            <template #title>
                                <i class="ri-checkbox-line top-plus-2 mr-2"></i> {{ $t('verification') }}
                            </template>
                            <div class="row my-1 my-md-5 px-0 px-lg-5">
                                <div class="col-12 col-md-8 col-lg-8 offset-md-2 offset-lg-2 px-3 px-md-0 px-lg-5">
                                    <div class="smsForm" v-if="verificationType=='sms_code'">
                                        <div class="mb-4 fadeIn">
                                            <sms-input v-model="sms" :timerFrom.sync="smsTimer" @timerExpired="timerExpired"></sms-input>
                                        </div>
                                        <div v-if="!smsAgain">
                                            <b-button type="button"
                                                      variant="primary"
                                                      size="lg"
                                                      block
                                                      @click="sendSms"
                                            >
                                                {{ $t('send_sms') }}
                                            </b-button>
                                        </div>
                                        <b-row v-if="smsAgain">
                                            <b-col>
                                                <b-button type="button"
                                                          variant="primary"
                                                          size="lg"
                                                          block
                                                          @click="verificationSms"
                                                          :disabled="smsTimer==0"
                                                >
                                                    {{ $t('verify') }}
                                                </b-button>
                                            </b-col>
                                            <b-col>
                                                <b-button type="submit"
                                                          variant="outline-secondary"
                                                          size="lg"
                                                          block
                                                          @click="sendSms()"
                                                          :disabled="smsTimer>0"
                                                >
                                                    {{ $t('send_again') }}
                                                </b-button>
                                            </b-col>
                                        </b-row>
                                    </div>
                                    <div class="userForm" v-if="verificationType=='personal_info'">
                                        <div class="fadeIn">
                                            <ValidationObserver ref="verificationWithPersonalInfoForm">
                                                <div class="row">
                                                    <div class="col-12 col-sm-6">
                                                        <ValidationProvider name="motherName" rules="required" v-slot="{valid, errors}">
                                                            <b-form-group :label="$t('mother_name')">
                                                                <b-form-input type="text"
                                                                              v-uppercase
                                                                              v-model="verificationWithPersonalInfoForm.mother_name"
                                                                              :state="errors[0] ? false : (valid ? true : null)">
                                                                </b-form-input>
                                                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                                            </b-form-group>
                                                        </ValidationProvider>
                                                    </div>
                                                    <div class="col-12 col-sm-6">
                                                        <ValidationProvider name="fatherName" rules="required" v-slot="{valid, errors}">
                                                            <b-form-group :label="$t('father_name')">
                                                                <b-form-input type="text"
                                                                              v-uppercase
                                                                              v-model="verificationWithPersonalInfoForm.father_name"
                                                                              :state="errors[0] ? false : (valid ? true : null)">
                                                                </b-form-input>
                                                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                                            </b-form-group>
                                                        </ValidationProvider>
                                                    </div>
                                                    <div class="col-12">
                                                        <ValidationProvider name="birthDate" rules="required" v-slot="{valid, errors}">
                                                            <b-form-group :label="$t('date_of_birth')" class="position-relative">
                                                                <v-date-picker
                                                                    v-model="verificationWithPersonalInfoForm.birthdate"
                                                                    :locale="$i18n.locale"
                                                                    is-expanded
                                                                    :masks="masks"
                                                                    :max-date="new Date()"
                                                                    :popover="{ 'visibility': 'click' }"
                                                                >
                                                                    <template v-slot="{ inputValue, inputEvents }">
                                                                        <b-input-group>
                                                                            <b-form-input
                                                                                :value="inputValue"
                                                                                v-on="inputEvents"
                                                                                :state="errors[0] ? false : (valid ? true : null)">
                                                                            </b-form-input>
                                                                            <b-input-group-append>
                                                                                <b-button
                                                                                    variant="outline-secondary"
                                                                                    class="btn-40"
                                                                                    disabled>
                                                                                    <i class="ri-calendar-line"></i>
                                                                                </b-button>
                                                                            </b-input-group-append>
                                                                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                                                        </b-input-group>
                                                                    </template>
                                                                </v-date-picker>
                                                            </b-form-group>
                                                        </ValidationProvider>
                                                    </div>
                                                </div>
                                            </ValidationObserver>
                                        </div>
                                        <b-button type="button" variant="primary" block @click="verificationWithPersonalInfo()">
                                            {{ $t('verify') }}
                                        </b-button>
                                    </div>
                                </div>
                            </div>
                        </b-tab>
                    </b-tabs>

                    <div class="d-flex justify-content-end" v-if="tabIndex==0">
                        <b-button type="button" :variant="maxTabIndex>tabIndex ? 'outline-primary':'outline-secondary'"
                                  :disabled="maxTabIndex>tabIndex ? false:true"
                                  @click="goNext();"
                        >
                            {{ $t('next') }}
                        </b-button>
                    </div>
                    <div class="d-flex justify-content-between" v-if="tabIndex==1">
                        <b-button type="button" variant="outline-primary" @click="goBack();">{{ $t('back') }}</b-button>
                        <b-button type="button" :variant="maxTabIndex>tabIndex ? 'outline-primary':'outline-secondary'" :disabled="maxTabIndex>tabIndex ? false:true"
                                  @click="goNext();"
                        >
                            {{ $t('next') }}
                        </b-button>
                    </div>
                    <div class="d-flex justify-content-between" v-if="tabIndex==2">
                        <b-button type="button" variant="outline-primary" @click="goBack();">{{ $t('back') }}</b-button>
                    </div>
                </div>
            </div>
        </div>
        <epayment-footer></epayment-footer>
    </div>
</template>

<script>
    import AlertDialogModal from "@/components/elements/AlertDialogModal";
    import Base from "@/plugins/Base";
    import CommonModal from "@/components/elements/CommonModal.vue";
    import EpaymentFooter from "./components/Footer"
    import EPaymentService from "@/services/EPaymentService"
    import SmsInput from "@/components/elements/SmsInput";
    import UserButton from "./components/UserButton"
    import moment from "moment";
    import {ValidationProvider, ValidationObserver, localeChanged} from "vee-validate"

    export default {
        props: {
            ln: {
                type: String,
                default: ''
            }
        },
        components: {
            AlertDialogModal,
            CommonModal,
            EpaymentFooter,
            SmsInput,
            UserButton,
            ValidationProvider,
            ValidationObserver
        },
        metaInfo() {
            return {
                title: this.$t("verification") + ' | ' + this.$t('epayment')
            }
        },
        data() {
            return {
                tabIndex: 0,
                maxTabIndex: 0,
                loginForm: {
                    identityNumber: "",
                },
                reference_id: this.$route.params.referenceId,
                token: null,
                verificationType: null,
                verificationOptions: null,
                verificationWithPersonalInfoForm: {
                    mother_name: '',
                    father_name: '',
                    birthdate: '2000-01-01',
                },
                masks: {
                    input: "DD.MM.YYYY",
                },
                sms: '',
                smsTimer: 0,
                smsAgain: false,
                visibleLoginMessage: true,
                virtualPosFiels: [
                    {key: 'code', label: this.toUpperCase('card_type')},
                    {key: 'name', label: this.toUpperCase('bank_name')},
                    {key: 'installments', label: this.toUpperCase('installment')},
                    {key: 'extra_installment', label: this.toUpperCase('bank_additional_installment')},
                    {key: 'total_installment', label: this.toUpperCase('total_installment')},
                ],
                virtualPosItems: [],
            }
        },
        beforeCreate() {
            const epaymentToken = Base.LocalStorage.get('epayment_token');
            const epaymentUser = Base.LocalStorage.get('epayment_user');
            if (epaymentToken && epaymentUser) {
                this.$store.dispatch('epayment/initAuth');
                this.$router.push('/epayment')
            }
        },
        created() {
            this.virtualPos();
            if(this.reference_id){
                EPaymentService.loginWithReferenceId(this.reference_id)
                .then(response=>{
                    const data = response.data.data;
                    this.verification(data.token, data.customer);
                    this.visibleLoginMessage=true;
                    if(this.ln.length===2){
                        this.$i18n.locale = this.ln;
                        localeChanged();
                    }
                });
            }
        },
        computed: {
            computeActiveTab() {
                return this.maxTabIndex >= this.tabIndex ? false : true
            }
        },
        watch: {
            tabIndex: function (val) {
                if (val == 0) {
                    this.verificationType = null;
                    this.maxTabIndex = 0;
                }
            },
            verificationType: function (val, exVal) {
                if (val) {
                    this.tabIndex = 3;
                    this.maxTabIndex = 3;
                    if (val == 'sms_code') {
                        this.sms = '';
                        this.smsTimer = 0;
                    }
                }
            },
        },
        methods: {
            scroll_right() {
                let content = document.querySelector(".nav-tabs");
                content.scrollLeft += 80;
            },
            goBack() {
                let content = document.querySelector(".nav-tabs");
                content.scrollLeft -= 80;
                this.tabIndex = this.tabIndex - 1;
            },
            goNext() {
                let content = document.querySelector(".nav-tabs");
                content.scrollLeft += 80;
                this.tabIndex = this.tabIndex + 1;
            },
            timerExpired(event) {
                this.smsTimer = 0;
            },
            virtualPos() {
                return EPaymentService.virtualPos()
                                      .then(response => {
                                          this.virtualPosItems = response.data.data;
                                          this.virtualPosItems.forEach((row,i)=>{
                                              var maxInstallemt = Math.max(...row.installments);
                                              var extraInstallment = row.extra_installment ? row.extra_installment:0;
                                              this.virtualPosItems[i].total_installment = maxInstallemt + extraInstallment;
                                          });
                                      });
            },
            async login() {
                const isValid = await this.$refs.loginForm.validate();
                if (isValid) {
                    return EPaymentService.login(this.loginForm)
                                          .then(response => {
                                              const data = response.data.data;
                                              this.token = data.token;
                                              if (data.options.length) {
                                                  this.verificationOptions = data.options;
                                              }
                                              this.maxTabIndex = 1;
                                              this.tabIndex = 1;
                                          })
                                          .catch(error => {
                                              if (error.status == 422) {
                                                  if (error.data.errors.email) {
                                                      this.$refs.loginForm.errors.identityNumberOrPassportNumber.push(error.data.errors.identity_number[0]);
                                                  }
                                              }
                                              else if (error.status == 406) {
                                                  this.$toast.error(this.$t('api.' + error.data.message));
                                              }
                                          });
                }
            },
            sendSms() {
                this.smsAgain = true;
                return EPaymentService.sendSms({token: this.token})
                                      .then(response => {
                                          const data = response.data.data;
                                          this.smsTimer = data.expires_in;
                                      })
                                      .catch(error => {
                                          this.$toast.error(this.$t('api.' + error.data.message));
                                      });
            },
            async verificationSms() {
                const formData = {
                    token: this.token,
                    sms_code: this.sms
                }
                return EPaymentService.verifySms(formData)
                                      .then(response => {
                                          const data = response.data.data;
                                          if (response.data.success) {
                                              this.verification(formData.token, data)
                                          }
                                          this.visibleLoginMessage=true;
                                          this.$toast.success(this.$t('api.' + response.data.message));
                                      })
                                      .catch(error => {
                                          if (error.status == 422) {
                                              if (error.data.errors.sms_code) {
                                                  this.$toast.error(error.data.errors.sms_code[0]);
                                              }
                                          }
                                          else if (error.status == 406) {
                                              this.$toast.error(this.$t('api.' + error.data.message));
                                          }
                                      });
            },
            getPosImage(code) {
                if (code == 'vakifbank') {
                    return require('@/assets/img/bank/world.svg');
                }
                else if (code == 'garanti') {
                    return require('@/assets/img/bank/bonus.svg');
                }
                else if (code == 'ziraatbank') {
                    return require('@/assets/img/bank/bankkart.svg');
                }
                else if (code == 'halkbank') {
                    return require('@/assets/img/bank/paraf.svg');
                }
                else if (code == 'isbank') {
                    return require('@/assets/img/bank/maximum.svg');
                }
                else if (code == 'akbank') {
                    return require('@/assets/img/bank/axess.svg');
                }
            },
            async verificationWithPersonalInfo() {
                const isValid = await this.$refs.verificationWithPersonalInfoForm.validate();
                if (isValid) {
                    const formData = this.verificationWithPersonalInfoForm;
                    formData.birthdate = moment(formData.birthdate).format('YYYY-MM-DD');
                    return EPaymentService.verifyPersonalInfo({token: this.token, ...formData})
                                          .then(response => {
                                              const data = response.data.data;
                                              if (response.data.success) {
                                                  this.verification(this.token, data)
                                              }
                                              this.visibleLoginMessage=true;
                                              this.$toast.success(this.$t('api.' + response.data.message));
                                          })
                                          .catch(error => {
                                              if (error.status == 422) {
                                                  if (error.data.errors.mother_name) {
                                                      this.$refs.verificationWithPersonalInfoForm.errors.motherName.push(error.data.errors.mother_name[0]);
                                                  }
                                                  if (error.data.errors.father_name) {
                                                      this.$refs.verificationWithPersonalInfoForm.errors.fatherName.push(error.data.errors.father_name[0]);
                                                  }
                                                  if (error.data.errors.birthdate) {
                                                      this.$refs.verificationWithPersonalInfoForm.errors.birthDate.push(error.data.errors.birthdate[0]);
                                                  }
                                              }
                                              else if (error.status == 406) {
                                                  this.$toast.error(this.$t('api.' + error.data.message));
                                              }
                                          });
                }
            },
            verification(token, data) {
                if (token) {
                    Base.LocalStorage.set('epayment_token', token);
                    this.$store.commit("epayment/setEpaymentToken", 'Bearer ' + data.token);
                }
                if (data) {
                    Base.LocalStorage.set('epayment_user', data);
                    this.$store.commit("epayment/setEpaymentUser", data)
                }
                this.$router.push('/epayment');
            },
        },
    }
</script>
