import Api from '@/services/Index';
import i18n from '@/plugins/i18n';

const create = async (formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    formData.language = 'EN'
    return Api.post('/student/documents/create', formData);
}

const downloadCourseSchedule = async (id) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post('/student/documents/course-schedule/' + id, null,{responseType: 'arraybuffer'});
}

const downloadDispatchPostponement = async (id, data) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    let formData = {
        ...data}
    return Api.post('/student/documents/dispatch-postponement/' + id, formData, {responseType: 'arraybuffer'});
}

const downloadExamSchedule = async (id) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post('/student/documents/exam-schedule/' + id, null,{responseType: 'arraybuffer'});
}

const downloadSchoolCertificate = async (id) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post('/student/documents/school-certificate/' + id, null,{responseType: 'arraybuffer'});
}

const downloadLanguageProficiency = async (id, note = null) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    let formData = {};
    if (note) {
        formData.note = note;
    }
    return Api.post('/student/documents/language-proficiency/' + id, formData, {responseType: 'arraybuffer'});
}

const downloadEnglishPlacementExam = async (id, ) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    let formData = {};
    return Api.post('/student/documents/english-placement-exam/' + id, formData, {responseType: 'arraybuffer'});
}

const downloadMilitaryStatus = async (id) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;

    return Api.post('/student/documents/military-status/' + id, null,{responseType: 'arraybuffer'});
}

const downloadNewPassword = async (id) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post('/student/documents/password/' + id, null,{responseType: 'arraybuffer'});
}

const downloadPrepTranscript = async (id, ) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post('/student/documents/prep-transcript/' + id, null,{responseType: 'arraybuffer'});
}

const downloadStudentCertificate = async (id, note = null) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    let data = {};
    if (note) {
        data.note = note;
    }
    return Api.post('/student/documents/student-certificate/' + id, data, {responseType: 'arraybuffer'});
}

const downloadTranscript = async (id) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post('/student/documents/transcript/' + id, null,{responseType: 'arraybuffer'})
}

const downloadWelcomeLetter = async (studentNumber, ) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post('/student/documents/welcome/' + studentNumber, null,{responseType: 'arraybuffer'});
}

const downloadGpaControl = async (id) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/student/documents/gpa-control/' + id);
}

const downloadDiploma = (id,formdata)=>{
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post('/student/documents/diploma/'+id, {...formdata},{responseType: 'arraybuffer'});
}

const downloadDiplomaAnnexes = (id)=>{
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post('/student/documents/diploma-supplement/'+id);
}

const downloadTemporaryGraduation = (id)=>{
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post('/student/documents/temporary-graduation-certificate/'+id,null,{responseType: 'arraybuffer'});
}

const downloadMinorCertificate = (id)=>{
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post('/student/documents/minor-certificate/'+id,null,{responseType: 'arraybuffer'});
}

const downloadAttendanceList = (section_ID)=>{
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post('/student/documents/attendance-list/'+section_ID,null,{responseType: 'arraybuffer'});
}

const downloaWeeklydAttendanceList = (section_ID)=>{
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/student/documents/weekly-attendance-list/'+section_ID,{responseType: 'arraybuffer'});
}

const downloadExamReport = (exam_ID='',classroom='') =>{
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post('/student/documents/exam-report/'+exam_ID+'/'+classroom,null,{responseType: 'arraybuffer'});
}

const downloadExamListReport = (exam_ID) =>{
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post('/student/documents/exam-report-list/'+exam_ID,null,{responseType: 'arraybuffer'});
}

const downloadRegistrationForm = (studentProgramId, formData) =>{
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post('/student/documents/registration-form/'+studentProgramId,formData,{responseType: 'arraybuffer'});
}

const downloadGpaStudentExcel = (studentProgramId) =>{
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/student/documents/gpa-control-excel-export/'+studentProgramId,{responseType: 'arraybuffer'});
}

const downloadDisciplineRegistry = async (id, data) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT
    return Api.post('/student/documents/discipline/' + id, data, {
        responseType: 'arraybuffer'
    })
}

const downloadExamReportAllSection = (examId = '', classroom = '') => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT
    return Api.post('/student/documents/all-section-exam-report/' + examId + '/' + classroom, null, {responseType: 'arraybuffer'})
}

export default {
    create,
    downloadCourseSchedule,
    downloadDispatchPostponement,
    downloadExamSchedule,
    downloadSchoolCertificate,
    downloadLanguageProficiency,
    downloadEnglishPlacementExam,
    downloadMilitaryStatus,
    downloadNewPassword,
    downloadPrepTranscript,
    downloadStudentCertificate,
    downloadTranscript,
    downloadWelcomeLetter,
    downloadGpaControl,
    downloadDiploma,
    downloadDiplomaAnnexes,
    downloadTemporaryGraduation,
    downloadMinorCertificate,
    downloadAttendanceList,
    downloadExamReport,
    downloadExamListReport,
    downloadRegistrationForm,
    downloadGpaStudentExcel,
    downloaWeeklydAttendanceList,
    downloadDisciplineRegistry,
    downloadExamReportAllSection
}

