<template>
    <div>
        <multiselect
            :class="validateError != '' ? 'box-border-color' : ''"
            :clear-on-select="!multiple"
            :close-on-select="!multiple"
            :deselect-label="''"
            :multiple="multiple"
            :options="options"
            :placeholder="$t('select')"
            :select-label="''"
            :selected-label="''"
            label="text"
            track-by="value"
            v-model="selected"
            @input="handleInput($event, multiple)"
        >
            <template slot="selection" slot-scope="{ values, search, isOpen}" v-if="multiple">
                <span v-if="values.length && !isOpen" class="multiselect__single">
                    {{ translateNSelected(values) }}
                </span>
            </template>
            <span slot="noOptions">{{ $t("no_options") }}</span>
            <span slot="noResult">{{ $t("no_result") }}</span>
        </multiselect>
        <span class="invalid-feedback-custom" v-show="validateError" v-html="validateError"/>
    </div>
</template>

<script>
// Helpers
import translateNSelected from "@/helpers/translateNSelected";
import handleInput from "@/helpers/handleInput";
import setSelected from "@/helpers/setSelected";

// Services
import SemesterService from "@/services/SemesterService";

export default {
    props: {
        value: {
            default: null,
        },
        multiple: {
            default: false,
        },
        validateError: {
            type: String,
            default: '',
        },
        activeYears: {
            type: Boolean,
            default: false,
        },
        modelId: {
            type: Boolean,
            default: false
        }
    },
    watch: {
        value: function (newValue) {
            this.selected = this.setSelected(newValue, this.options, this.multiple)
        }
    },
    data() {
        return {
            selected: null,
            options: [],
            activeDate: null
        }
    },
    created() {
        this.getOptions()
    },
    methods: {
        handleInput: handleInput,
        setSelected: setSelected,
        translateNSelected: translateNSelected,
        async getOptions() {
            if(this.modelId) {
                const key = 'academic_years_id';
                const local = this.getWithExpiry(key);
                if(local){
                    this.setOptions(local);
                    this.selected = this.setSelected(this.value, this.options, this.multiple)
                    return;
                }

              const response = await  SemesterService.getAll()
              const data = response.data.data
              const academicYears = data.map(item => ({text: `${item.academic_year} ${this.$t(item.semester_text.toLowerCase())}`, value: item.id}))
              this.setWithExpiry(key, academicYears);
              this.setOptions(academicYears);
              return
            }
            const key = 'academic_years';

            let local = this.getWithExpiry(key);
            if(local){
                this.setOptions(local);
                this.selected = this.setSelected(this.value, this.options, this.multiple)
                if(this.activeYears){
                    this.selected = [{text:local.active,value:local.active}]
                    this.$emit('year',this.selected)
                }
                return;
            }

            SemesterService.academicYears()
                .then((response) => {
                    const data = response.data.data;
                    this.setWithExpiry(key, data);
                    this.setOptions(data);
                    this.activeDate = response.data.data?.active
                })
                .then(() => {
                    this.selected = this.setSelected(this.value, this.options, this.multiple)
                })
                .then(()=>{
                    if(this.activeYears){
                        this.selected = [{text:this.activeDate,value:this.activeDate}]
                        this.$emit('year',this.selected)
                    }
                })
        },
        setOptions(data){
            if(this.modelId) {
                this.options = data
                return
            }
            data?.options.forEach((item) => {
                this.options.push({
                    value: item,
                    text: item,
                })
            })
        }
    }
}
</script>
