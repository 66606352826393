<template>
    <div class="d-flex flex-column vh-100">
        <div class="container py-5 my-0 my-sm-5 px-4 not-layout">
            <div class="row">
                <div class="col-12 col-sm-12 col-md-12 col-lg-10 col-xl-8 offset-lg-1 offset-xl-2">
                    <div class="row mb-2">
                        <div class="col-12">
                            <div class="row align-items-center mb-0 pb-5 mb-sm-2">
                                <div class="col-4 text-left">
                                    <div class="logo-wrapper"><img src="@/assets/img/berlin_logo.png"/></div>
                                </div>
                                <div class="col-4 text-center">
                                    <h4 class="mb-0">{{ $t('epayment') }}</h4>
                                </div>
                                <div class="col-4 text-right">
                                    <user-button :nameSurname="epaymentUser.name + ' ' + epaymentUser.surname" :isLogin="true" @epaymentLogout="logout"></user-button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <b-tabs content-class="py-5 position-relative" v-model="tabIndex">
                        <div class="tabs-bottom-line"></div>
                        <b-tab @click="clearDebt()">
                            <template #title><i class="ri-price-tag-line top-plus-2 mr-2"></i>{{ $t('fees_and_installments') }}</template>

                            <!-- Loading -->
                            <div class="spinner over text-center" v-if="debtsLoading">
                                <b-spinner label="Spinning" variant="primary"></b-spinner>
                            </div>
                            <div v-else>
                                <div class="text-center py-5 my-5 w-100" v-if="
                                !((debts.payment_plans && debts.payment_plans.length)
                                || (debts.payments && debts.payments.length)
                                || (debts.other_payments && debts.other_payments.length))">
                                    <div class="mb-5">
                                        <i class="ri-calculator-line ri-5x text-muted"></i>
                                    </div>
                                    <h4 class="mb-4 text-black-50">{{ $t('you_dont_have_a_payment_plan') }}</h4>
                                </div>
                            </div>

                            <!-- Debts -->
                            <div class="row my-1 my-md-5" v-show="!debtsLoading">
                                <div class="col-12 col-md-10 col-lg-10 offset-md-1 offset-lg-1">
                                    <b-card no-body class="mb-3 border-0" v-for="(item1, key1) in debts.payment_plans" :key="'accordion1-'+key1">
                                        <b-card-header header-tag="header" class="px-0 py-1 m-0 border-0 bg-transparent" role="tab">
                                            <b-button block v-b-toggle="'accordion1-'+key1"
                                                      variant="secondary bg-gray-100 text-dark text-capitalize h-auto font-weight-medium line-height-normal py-2">
                                                <div class="d-block d-md-flex justify-content-between w-100">
                                                    <div class="text-left mb-1 mb-md-0 pr-3 d-flex align-items-center">
                                                        {{ item1.program.name }}
                                                        {{ item1.academic_year ? ' - ' + item1.academic_year : '' }} -
                                                        {{ item1.product_name }}
                                                        {{item1.explanation?' ('+item1.explanation+')':''}}
                                                    </div>
                                                    <div class="text-left font-weight-bold white-space-nowrap h6 mb-0">
                                                        {{ item1.cash_price | formatToCurrency(item1.currency) }}
                                                    </div>
                                                </div>
                                            </b-button>
                                        </b-card-header>
                                        <b-collapse :id="'accordion1-'+key1" accordion="my-accordion" role="tabpanel">
                                            <b-card-body class="px-0 py-3">

                                                <!-- Peşin Ödeme  -->
                                                <b-card no-body class="mb-3 border-0" v-if="item1.allow_cash">
                                                    <b-card-header header-tag="header" class="p-0 m-0 border-0 bg-transparent" role="tab">
                                                        <b-button block v-b-toggle.accordion-pesin variant="outline-primary text-capitalize font-weight-medium line-height-normal">
                                                            <div class="d-flex justify-content-between align-items-center w-100">
                                                                <div class="text-left d-flex align-items-center text-uppercase">
                                                                    <i class="ri-dual-sim-1-line mr-2 font-size-1"></i>
                                                                    <span class="top-plus-1">{{ $t('advance_payment') }}</span>
                                                                </div>
                                                                <div class="text-left font-weight-bold white-space-nowrap h6 mb-0">
                                                                    {{ item1.cash_price | formatToCurrency(item1.currency) }}
                                                                </div>
                                                            </div>
                                                        </b-button>
                                                    </b-card-header>
                                                    <b-collapse id="accordion-pesin" accordion="accordion-1" role="tabpanel">
                                                        <b-card-body class="px-0">
                                                            <b-card-text>
                                                                <b-table
                                                                    :empty-text="$t('there_are_no_records')"
                                                                    bordered
                                                                    striped
                                                                    responsive
                                                                    :items="modifyProgramStatusItems('advance',item1)"
                                                                    :fields="modifyProgramStatusFields('advance')"
                                                                    show-empty
                                                                    class="mb-3 table-dropdown no-scrollbar border rounded">
                                                                    <template #cell(total_price)="data">
                                                                        {{ data.value | formatToCurrency(item1.currency) }}
                                                                    </template>
                                                                    <template #cell(payments_sum_amount)="data">
                                                                        {{ data.value | formatToCurrency(item1.currency) }}
                                                                    </template>
                                                                    <template #cell(debt)="data">
                                                                        {{ data.value | formatToCurrency(item1.currency) }}
                                                                    </template>
                                                                </b-table>
                                                                <b-button type="button"
                                                                          variant="primary btn-block"
                                                                          @click="paymentSetVars('payment_plan', item1.uuid, 'advance')">
                                                                    {{ $t('pay') }}
                                                                </b-button>
                                                            </b-card-text>
                                                        </b-card-body>
                                                    </b-collapse>
                                                </b-card>

                                                <!-- Taksitli Ödeme -->
                                                <b-card no-body class="mb-3 border-0" v-if="item1.allow_installment">
                                                    <b-card-header header-tag="header" class="p-0 m-0 border-0 bg-transparent" role="tab">
                                                        <b-button block v-b-toggle.accordion-taksit variant="outline-primary text-capitalize font-weight-medium line-height-normal">
                                                            <div class="d-flex justify-content-between align-items-center w-100">
                                                                <div class="text-left d-flex align-items-center text-uppercase">
                                                                    <i class="ri-menu-line mr-2 font-size-1"></i>
                                                                    <span class="top-plus-1">{{ $t('installment_payment') }}</span>
                                                                </div>
                                                                <div class="text-left font-weight-bold white-space-nowrap h6 mb-0">
                                                                    {{ item1.price | formatToCurrency(item1.currency) }}
                                                                </div>
                                                            </div>
                                                        </b-button>
                                                    </b-card-header>
                                                    <b-collapse id="accordion-taksit" accordion="accordion-1" role="tabpanel">
                                                        <b-card-body class="px-0">
                                                            <b-card-text>
                                                                <template>
                                                                    <b-table
                                                                        :empty-text="$t('there_are_no_records')"
                                                                        bordered
                                                                        striped
                                                                        responsive
                                                                        :items="modifyProgramStatusItems('installment',item1)"
                                                                        :fields="modifyProgramStatusFields('installment')"
                                                                        show-empty
                                                                        class="mb-3 table-dropdown no-scrollbar border rounded">
                                                                        <template #cell(total_price)="data">
                                                                            {{ data.value | formatToCurrency(item1.currency) }}
                                                                        </template>
                                                                        <template #cell(payments_sum_amount)="data">
                                                                            {{ data.value | formatToCurrency(item1.currency) }}
                                                                        </template>
                                                                        <template #cell(debt)="data">
                                                                            {{ data.value | formatToCurrency(item1.currency) }}
                                                                        </template>
                                                                    </b-table>
                                                                </template>
                                                                <template>
                                                                    <b-table
                                                                        :empty-text="$t('there_are_no_records')"
                                                                        bordered
                                                                        striped
                                                                        responsive
                                                                        show-empty
                                                                        :fields="modifyPosFields( 'installment')"
                                                                        :items="modifyPosItems( 'installment',debts.virtual_pos, item1.currency)"
                                                                        class="mb-3 table-dropdown no-scrollbar border rounded"
                                                                    >
                                                                        <template #cell(card_type)="data">
                                                                            <img :src="getPosImage(data.value)" style="max-height:18px; max-width:70px">
                                                                        </template>
                                                                        <template #cell(installments)="data">
                                                                            {{ data.value.map(i => i +(data.item.extra_installment>0?'+'+data.item.extra_installment:'')+ ' ' + $t('installment')).join(", ") }}
                                                                        </template>
                                                                    </b-table>
                                                                    <b-button type="button"
                                                                              variant="primary btn-block"
                                                                              @click="paymentSetVars('payment_plan', item1.uuid, 'installment')">
                                                                        {{ $t('pay') }}
                                                                    </b-button>
                                                                </template>
                                                            </b-card-text>
                                                        </b-card-body>
                                                    </b-collapse>
                                                </b-card>
                                            </b-card-body>
                                        </b-collapse>
                                    </b-card>

                                    <!-- Payments -->
                                    <b-card no-body class="mb-3 border-0" v-for="(item2, key2) in debts.payments" :key="'accordion2-'+key2">
                                        <b-card-header header-tag="header" class="px-0 py-1 m-0 border-0 bg-transparent" role="tab">
                                            <b-button block v-b-toggle="'accordion2-'+key2" variant="secondary bg-gray-100 text-dark text-capitalize h-auto font-weight-medium line-height-normal py-2">
                                                <div class="d-block d-md-flex justify-content-between w-100">
                                                    <div class="text-left text-lg-center mb-1 mb-lg-0">
                                                        {{ item2.program.name }}
                                                        {{ item2.academic_year ? ' - ' + item2.academic_year : '' }}
                                                        {{ item2.product_name }}
                                                    </div>
                                                    <div class="text-left text-lg-center font-weight-bold">
                                                        {{ item2.amount | formatToCurrency(item2.currency) }}
                                                    </div>
                                                </div>
                                            </b-button>
                                        </b-card-header>
                                        <b-collapse :id="'accordion2-'+key2" accordion="my-accordion" role="tabpanel">
                                            <b-card-body class="px-0 py-3">
                                                <p class="mb-1">{{ $t('advance_payment') }}</p>
                                                <div class="border rounded p-3 mb-3">
                                                    <b-row>
                                                        <b-col class="d-flex flex-column justify-content-center">
                                                            <h4 class="text-center mb-0">{{ item2.amount | formatToCurrency(item2.currency) }}</h4>
                                                        </b-col>
                                                        <b-col>
                                                            <b-button type="button" variant="primary btn-block"
                                                                      @click="paymentSetVars('payment', item2.uuid,'advance')">
                                                                {{ $t('pay') }}
                                                            </b-button>
                                                        </b-col>
                                                    </b-row>
                                                </div>
                                            </b-card-body>
                                        </b-collapse>
                                    </b-card>

                                    <!-- Other Payments -->
                                    <b-card no-body class="mb-3 border-0" v-for="(item2, key2) in debts.other_payments" :key="'accordion2-'+key2">
                                        <b-card-header header-tag="header" class="px-0 py-1 m-0 border-0 bg-transparent" role="tab">
                                            <b-button block v-b-toggle="'accordion2-'+key2" variant="secondary bg-gray-100 text-dark text-capitalize h-auto font-weight-medium line-height-normal py-2">
                                                <div class="d-block d-md-flex justify-content-between w-100">
                                                    <div class="text-left text-lg-center mb-1 mb-lg-0">
                                                        {{ item2.program.name }}
                                                        {{ item2.academic_year ? ' - ' + item2.academic_year : '' }}
                                                        {{ item2.product_name }}
                                                    </div>
                                                    <div class="text-left text-lg-center font-weight-bold">
                                                        {{ item2.amount | formatToCurrency(item2.currency) }}
                                                    </div>
                                                </div>
                                            </b-button>
                                        </b-card-header>
                                        <b-collapse :id="'accordion2-'+key2" accordion="my-accordion" role="tabpanel">
                                            <b-card-body class="px-0 py-3">
                                                <p class="mb-1">{{ $t('advance_payment') }}</p>
                                                <div class="border rounded p-3 mb-3">
                                                    <b-row>
                                                        <b-col class="d-flex flex-column justify-content-center">
                                                            <h4 class="text-center mb-0">{{ item2.amount | formatToCurrency(item2.currency) }}</h4>
                                                        </b-col>
                                                        <b-col>
                                                            <b-button type="button" variant="primary btn-block"
                                                                      @click="paymentSetVars('other_payment', item2.uuid,'advance')">
                                                                {{ $t('pay') }}
                                                            </b-button>
                                                        </b-col>
                                                    </b-row>
                                                </div>
                                            </b-card-body>
                                        </b-collapse>
                                    </b-card>
                                </div>
                            </div>
                        </b-tab>

                        <b-tab :disabled="paymentDebtId ? false:true">
                            <template #title><i class="ri-bank-card-line top-plus-2 mr-2"></i>{{ $t('pay') }}</template>
                            <div class="row my-1 my-md-5">

                                <div class="col-12">
                                    <div v-if="paymentFormMaxAmount===0 && paymentFormSumAmount>0">
                                        <div class="text-center py-5 my-5 fadeIn d-block h-auto show">
                                            <div class="mb-5">
                                                <i class="ri-checkbox-circle-line ri-6x text-success"></i>
                                            </div>
                                            <h4>{{ $t('your_payment_transaction_has_been_successfully_completed') }}</h4>
                                        </div>
                                    </div>
                                    <ValidationObserver ref="payForm">
                                        <div class="row" v-if="paymentFormMaxAmount>0">
                                            <div class="col-12">
                                                <div class="fadeIn show d-block mb-4">
                                                    <h5 class="mb-0">
                                                        <span v-if="paymentDebt.program">
                                                            {{ paymentDebt.program.name }}
                                                        </span>
                                                        <span v-if="paymentDebt.academic_year">
                                                            {{ paymentDebt.academic_year ? ' - ' + paymentDebt.academic_year : '' }}
                                                        </span>
                                                        <span v-if="paymentDebt.product_name">
                                                            {{ ' - '+paymentDebt.product_name }}
                                                            {{ paymentDebt.explanation ? '('+paymentDebt.explanation+')':'' }}
                                                        </span>
                                                    </h5>
                                                </div>
                                            </div>
                                            <div class="col-12 col-md-4">
                                                <div class="row">
                                                    <div class="col-6 col-md-12" v-if="paymentFormPrice!=null && paymentDebt.currency">
                                                        <b-form-group :label="$t('total_amount')" >
                                                            <div><b>{{ paymentFormPrice | formatToCurrency(paymentDebt.currency) }}</b></div>
                                                        </b-form-group>
                                                    </div>
                                                    <div class="col-6 col-md-12" v-if="paymentFormSumAmount!=null && paymentDebt.currency">
                                                        <b-form-group :label="$t('amount_paid')" >
                                                            <div><b>{{ paymentFormSumAmount | formatToCurrency(paymentDebt.currency) }}</b></div>
                                                        </b-form-group>
                                                    </div>
                                                    <div class="col-12">
                                                        <ValidationProvider name="payment_total" rules="required" v-slot="{valid, errors}">
                                                            <b-form-group :label="$t('payment_total')">
                                                                <b-form-input type="number"
                                                                              v-model.sync="paymentFormAmount"
                                                                              min="1"
                                                                              :max="Math.ceil(parseFloat(paymentFormMaxAmount))"
                                                                              @focusout="paymentFormAmountCalculate"
                                                                              v-check-min-max-value
                                                                              :state="errors[0] ? false : (valid ? true : null)">
                                                                </b-form-input>
                                                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                                            </b-form-group>
                                                        </ValidationProvider>
                                                    </div>
                                                    <div class="col-6 col-md-12" v-if="paymentDebt.currency">
                                                        <b-form-group :label="$t('remaining_amount')" >
                                                            <div><b>{{ paymentFormRemainingAmount | formatToCurrency(paymentDebt.currency) }}</b></div>
                                                        </b-form-group>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-12 col-md-8">
                                                <div class="row">
                                                    <div class="col-12">
                                                          <ValidationProvider name="card_number" rules="required" v-slot="{valid, errors}">
                                                            <b-form-group :label="$t('card_number')" class="position-relative">
                                                                <span class="bank-input-logo" v-if="paymentFormCreditCardLogo">
                                                                    <img :src="getPosImage(paymentFormCreditCardLogo)" style="max-height: 18px; max-width: 70px;">
                                                                </span>
                                                                <b-form-input type="text"
                                                                              v-model="paymentFormCreditCardNumber"
                                                                              :state="errors[0] ? false : (valid ? true : null)" v-mask="'#### #### #### ####'"
                                                                              autocomplete="off"
                                                                >
                                                                </b-form-input>
                                                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                                            </b-form-group>
                                                        </ValidationProvider>
                                                    </div>
                                                    <div class="col-12">
                                                        <ValidationProvider name="card_owner" rules="required" v-slot="{valid, errors}">
                                                            <b-form-group :label="$t('card_owner')">
                                                                <b-form-input type="text"
                                                                              v-model="paymentFormCardHolderName"
                                                                              :state="errors[0] ? false : (valid ? true : null)"
                                                                              autocomplate="off"
                                                                              v-uppercase
                                                                >
                                                                </b-form-input>
                                                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                                            </b-form-group>
                                                        </ValidationProvider>
                                                    </div>
                                                    <div class="col-4">
                                                        <ValidationProvider name="expiry_month" rules="required" v-slot="{valid, errors}">
                                                            <fieldset class="form-group">
                                                                <legend tabindex="-1" class="bv-no-focus-ring col-form-label pt-0">{{ $t('month') }}</legend>
                                                                <multiselect
                                                                    :placeholder="$t('select')"
                                                                    v-model="paymentFormExpiryMonth"
                                                                    :options="paymentFormMonthOptions"
                                                                    :select-label="''"
                                                                    :selected-label="''"
                                                                    :deselect-label="''"
                                                                    :searchable="false"
                                                                    :allow-empty="true"
                                                                    :class="errors[0] ? 'is-invalid' : (valid ? 'is-valid' : null)"
                                                                >
                                                                </multiselect>
                                                                <div class="invalid-feedback d-block" v-if="errors[0]" v-html="errors[0]"></div>
                                                            </fieldset>
                                                        </ValidationProvider>
                                                    </div>
                                                    <div class="col-4">
                                                        <ValidationProvider name="expiry_year" rules="required" v-slot="{valid, errors}">
                                                            <fieldset class="form-group">
                                                                <legend tabindex="-1" class="bv-no-focus-ring col-form-label pt-0">{{ $t('year') }}</legend>
                                                                <multiselect
                                                                    :placeholder="$t('select')"
                                                                    v-model="paymentFormExpiryYear"
                                                                    :options="paymentFormYearOptions"
                                                                    :select-label="''"
                                                                    :selected-label="''"
                                                                    :deselect-label="''"
                                                                    :searchable="false"
                                                                    :class="errors[0] ? 'is-invalid' : (valid ? 'is-valid' : null)"
                                                                >
                                                                </multiselect>
                                                                <div class="invalid-feedback d-block" v-if="errors[0]" v-html="errors[0]"></div>
                                                            </fieldset>
                                                        </ValidationProvider>
                                                    </div>
                                                    <div class="col-4">
                                                        <ValidationProvider name="cvc" rules="required" v-slot="{valid, errors}">
                                                            <b-form-group label="CVC">
                                                                <b-form-input type="text"
                                                                              v-model="paymentFormCvv"
                                                                              :state="errors[0] ? false : (valid ? true : null)"
                                                                              v-mask="'###'">
                                                                </b-form-input>
                                                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                                            </b-form-group>
                                                        </ValidationProvider>
                                                    </div>
                                                    <div class="col-12" v-if="paymentType=='installment'">
                                                        <ValidationProvider name="installment" rules="required" v-slot="{valid, errors}">
                                                            <fieldset class="form-group">
                                                                <legend tabindex="-1" class="bv-no-focus-ring col-form-label pt-0">{{ $t('installment_options') }}</legend>
                                                                <multiselect
                                                                    :placeholder="$t('select')"
                                                                    v-model="paymentFormInstallment"
                                                                    :options="paymentFormInstallmentOptions"
                                                                    :select-label="''"
                                                                    :selected-label="''"
                                                                    :deselect-label="''"
                                                                    label="name"
                                                                    :custom-label="paymentFormInstallmentCustomLabel"
                                                                    :disabled="paymentFormInstallmentDisabled"
                                                                    :class="errors[0] ? 'is-invalid' : (valid ? 'is-valid' : null)"
                                                                >
                                                                </multiselect>
                                                                <div class="invalid-feedback d-block" v-if="errors[0]" v-html="errors[0]"></div>
                                                            </fieldset>
                                                        </ValidationProvider>
                                                    </div>

                                                    <div class="col-12">
                                                        <ValidationProvider name="installment_options" rules="required" v-slot="{valid, errors}">
                                                            <fieldset class="form-group">
                                                                <b-form-checkbox v-model="paymentFormCheck" value="true" unchecked-value="">
                                                                    <div>
                                                                        <a href="#" v-b-modal.CommonModal @click="showModal('onBilgilendirme')">
                                                                            Ön Bilgilendirme Formu
                                                                        </a>
                                                                        'nu okudum onaylıyorum.
                                                                    </div>
                                                                    <div class="invalid-feedback d-block" v-if="errors[0]">
                                                                        Bu alan zorunludur
                                                                    </div>
                                                                </b-form-checkbox>
                                                            </fieldset>
                                                        </ValidationProvider>
                                                    </div>
                                                    <div class="col-12">
                                                        <b-button type="button" variant="primary btn-block" @click="pay">
                                                            <div class="d-flex justify-content-between w-100">
                                                                <div>{{ $t('pay') }}</div>
                                                                <div v-if="paymentFormShowAmount && paymentDebt.currency">
                                                                    {{ paymentFormShowAmount | formatToCurrency(paymentDebt.currency) }}
                                                                </div>
                                                            </div>
                                                        </b-button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </ValidationObserver>
                                </div>
                            </div>
                        </b-tab>

                        <b-tab v-if="debts && debts.done_payments">
                            <template #title><i class="ri-file-download-line top-plus-2 mr-2"></i>{{ $t('receipts') }}</template>
                            <div class="row my-1 my-md-5">

                                <div class="col-12">
                                    <table class="table table-bordered">
                                        <tr>
                                            <th>{{$t('date')}}</th>
                                            <th>{{$t('amount')}}</th>
                                            <th class="w-40"></th>
                                        </tr>
                                        <tr v-for="payment in debts.done_payments">
                                            <td>{{payment.date}}</td>
                                            <td>{{payment.amount}} {{payment.currency}}</td>
                                            <td><b-button @click="downloadReceipt(payment.id)" variant="outline-success" size="xs">{{$t('download')}}</b-button></td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </b-tab>
                    </b-tabs>
                </div>
            </div>
        </div>

        <CommonModal ref="threeDPayModal" @bHideModalHeaderClose="closedThreeDPayModal($event)" :onHideOnlyX="true">
            <template v-slot:CommonModalTitle>
                {{ $t('pay') }}
            </template>
            <template v-slot:CommonModalContent>
                <iframe sandbox="allow-same-origin allow-top-navigation allow-forms allow-scripts" :srcdoc="paymentResult" width="100%" height="550px" class="border-0"></iframe>
            </template>
        </CommonModal>

        <CommonModal ref="paymentModal" :size="commonModalModeSize" :onHideOnlyX="true" >
            <template v-slot:CommonModalTitle>
                <div v-if="commonModalMode == 'onBilgilendirme'">{{ $t('preliminary_information_form') }}</div>
                <div v-if="commonModalMode == 'mesafeliSatis'">{{ $t('distance_sales_agreement') }}</div>
            </template>
            <template v-slot:CommonModalContent>
                <div v-if="commonModalMode == 'onBilgilendirme'">
                    <div v-if="$i18n.locale=='en'">
                        <h5>ONLINE PAYMENT GENERAL TERMS AND CONDITIONS</h5>

                        <p>1. I am using the “online payment” system on BAHÇEŞEHİR UNIVERSITY’s website of my own volition.</p>
                        <p>2. I will not share the password of the debit or credit card that I will use on the “online payment” system with anyone; under no circumstances will I allow third parties access to my account; I acknowledge that so long as the correct password is used, as far as BAHÇEŞEHİR UNIVERSITY is concerned, the transactions were made by me and that no other measures will be taken to verify their validity; I will take any and all precautions to ensure that my password and other verification codes will not be discovered by third parties, especially those who may have ill intent; and I accept all responsibility as far these matters are concerned.</p>
                        <p>3. I take full responsibility for any erroneous payments that may be made as a result of my negligence or mistake; that BAHÇEŞEHİR UNIVERSITY will bear no responsibility and I will make no claims or demands under any assumed name.</p>
                        <p>4. I am fully aware that the online payment system may only be used to make full payments on amounts owing; that the system will not accept partial or down payments.</p>
                        <p>5. I am fully aware that the system may only be used to make full payments on current amounts owing; that it may not be used to make any late, future or default payments; that under no circumstances will any payment made online be considered a payment toward a late, future or default payment.   </p>
                        <p>6. I have been made fully aware and acknowledge that I may benefit from discounted tuition offers made by BAHÇEŞEHİR UNIVERSITY during certain registration periods and announced via various outlets; outside of these periods, I may not benefit from any discount when registering with the “online payment” system; that having benefitted from discounted tuition in the past does not guarantee or contractually oblige BAHÇEŞEHİR UNIVERSITY to make any such offers in the future; that BAHÇEŞEHİR UNIVERSITY exclusively reserves the right to withdraw any offer at any time, to change the amount of the discount, and any and all rights regarding discounted tuition offers. </p>
                        <p>7. I acknowledge that BAHÇEŞEHİR UNIVERSITY will not share my personal account details with any third parties. </p>
                        <p>8. I acknowledge that any breach of contract on my part may result in BAHÇEŞEHİR UNIVERSITY barring my access to the “online payment” system and I may be held accountable for any damages or losses incurred by the university because of my actions.  </p>
                        <p>9. I acknowledge that BAHÇEŞEHİR UNIVERSITY is not responsible for any damages or losses, directly, indirectly, incidentally, or intentionally resulting from operational errors, loss of communications, negligence, power outages, break downs, maintenance works, etc. that may cause the suspension of the system, delays, computer viruses, cable or system failures.  </p>
                        <p>10. I acknowledge that any information, material, or content on the “online payment” pages of the website may be suspended, withdrawn, or changed without notice; that the terms and conditions may be revised without notice during updates; and I agree to keep up to date with all developments and consent to all potential changes to maintain my access to the system.   </p>
                        <p>11. I hereby declare that I will comply with all written terms and conditions outlined in this document, any relevant laws and legal provisions, and accept in advance any and all decisions made by BAHÇEŞEHİR UNIVERSITY.</p>
                        <p>I have read, understood, and agree to these terms and conditions.</p>
                    </div>
                    <div v-else>
                        <h5>ONLİNE ÖDEME GENEL ŞARTLAR VE TAAHHÜTNAME</h5>

                        <p>1. BAHÇEŞEHİR ÜNİVERSİTESİ’ne ait ‘’online ödeme’’ web sitesini kendi rızamla kullandığımı,</p>
                        <p>2. “Online ödeme” sisteminde kullandığım Bankamatik kartı/ kredi kartının şifrelerini gizli tutacağımı, her ne suretle olursa olsun 3. Kişilerin kulanımına izin vermeyeceğimi, işlemler doğru şifre ile yapıldığı müddetçe BAHÇEŞEHİR ÜNİVERSİTESİ tarafından şahsımca yapılmış olarak kabul edileceğini ve işlemin gerçekliğine dair ayrıca bir kontrol yapılmayacağını, şifrelerin kaybolmaması ver kötü niyetli kişilerin eline geçmemesi için gerekli her türlü tedbiri alacağımı, bu hususlarla ilgili her türlü sorumluluğun tarafıma ait olduğunu,</p>
                        <p>3. Kendi kusur ve ihmalim sebebiyle hatalı bir ödeme işlemi yapmam halinde buna ilişkin her türlü zarardan şahsen sorumlu olacağımı, BAHÇEŞEHİR ÜNİVERSİTESİ’nin hiçbir sorumluluğunun doğmayacağını ve BAHÇEŞEHİR ÜNİVERSİTESİ’nden herhangi bir nam veya ad altında talepte bulunmayacağımı,</p>
                        <p>4. Online ödeme sisteminin muaccel borç bakiyesinin tümünün ödenmesi için kullanılabileceğini, muaccel borç bakiyesi için kısmı ödeme yapılamayacağını, online ödeme sisteminin kapora ödemesi veya kısmi ödemeyi kabul etmeyeceğini bildiğimi,</p>
                        <p>5. Online ödeme sistemi ile yapılan ödemelerin sadece güncel borçlar için kullanılabileceğini, gecikmiş/temerrüde düşmüş ve/veya henüz muaccel olmamış borçlar için kullanılamayacağını,online ödeme sistemi kullanılarak yapılan ödemelerin hiçbir surette gecikmiş/temerrüde düşmüş veya henüz vadesi gelmemiş borçlara mahsuben yapılmış sayılmayacağını bildiğimi,</p>
                        <p>6. BAHÇEŞEHİR ÜNİVERSİTESİ’nin belirlemiş olduğu ve çeşitli bildirim vasıtalarıyla duyurduğu dönemlerde indirimli kayıt imkanını kullanabileceğimi, belirtilmiş olan dönemlerin dışında online ödeme sistemi ile kayıt yaptırdığımda  herhangi bir indirimli kayıt imkanından faydalanamayacağımı,indirimli kayıt imkanından bir kez faydalanmış olmanın ileriki dönemlerde de BAHÇEŞEHİR ÜNİVERSİTESİ’nin indirimli kayıt imkanı sunacağına dair bir garanti /taahhüt anlamına gelmeyeceğini, BAHÇEŞEHİR ÜNİVERSİTESİ’nin sağlamış olduğu indirimli kayıt imkanını  bütünüyle kaldırabileceğini, indirimli kayıt imkanında belirlediği indirim oranlarını değiştirebileceğini, bu husus ile ilgili tüm tasarruf/takdir hakkının münhasır olarak olarak BAHÇEŞEHİR ÜNİVERSİTESİ’nde olduğunu bildiğimi ve bununla ilgili olarak bilgilendirildiğimi,,</p>
                        <p>7. BAHÇEŞEHİR ÜNİVERSİTESİ’nin kişiye özel hesap bilgilerini 3. kişilerle paylaşmayacağını,</p>
                        <p>8. Sözleşmeye aykırı kullanımım halinde BAHÇEŞEHİR ÜNİVERSİTESİ’nin “online ödeme” sistemine erişimimi engelleyebileceğini ve bu sözleşmeye aykırı kullanım nedeniyle BAHÇEŞEHİR ÜNİVERSİTESİ’nin uğrayacağı zarar ve kayıplardan sorumlu olacağımı,</p>
                        <p>9. İşlemlerin gerçekleşmesi sırasında operasyonda ve iletimde başarısızlık/performans kaybı/hata/kasıtsız ihmal /kesinti/bozulma/gecikmelerden, bakım çalışması ve sair sebepler nedeniyle sistemin askıya alınmasından,işlem emrinin verilmesi zamanı ile gerçekleşmesi zamanı arasındaki farklılıklardan, bilgisayar virüsü , kablo veya sistem hatası dolayısıyla ortaya çıkabilecek doğrudan ya da dolaylı özel veya tesadüfi netice itibariyle meydana gelen her türlü zarar/kayıplardan BAHÇEŞEHİR ÜNİVERSİTESİ’nin sorumlu olmayacağını,</p>
                        <p>10. Online ödeme sitesindeki sayfalarda  yer alan her türlü bilgi, materyal ve içeriğin herhangi bir ihbarda bulunulmadan askıya alınabileceğini, geri çekilebileceğini  veya değiştirilebileceğini, şartlardaki değişikliklerin herhangi bir ihbarda bulunulmadan bu sayfanın güncellenmesi ile gerçekleştirileceğini, şartları düzenli olarak gözden geçireceğimi ve siteye erişimimi devam ettirmekle bu şartlarda yapılabilecek herhangi bir değişiklikte mutabık olduğumu,</p>
                        <p>11. İşbu taahhütnamede belirtilmiş olan yazılı koşullara, ilgili kanun ve yönetmelik hükümlerine, bu konuda BAHÇEŞEHİR ÜNİVERSİTESİ’nce alınacak her türlü karara, uymayı peşinen, beyan, kabul ve taahhüt ederim.</p>
                        <p>Okudum, anladım kabul ediyorum.</p>
                    </div>
                </div>
            </template>
        </CommonModal>
        <epayment-footer></epayment-footer>
    </div>
</template>

<script>
    import EPaymentService from "@/services/EPaymentService"
    import EpaymentFooter from "./components/Footer"
    import CommonModal from "@/components/elements/CommonModal";
    import UserButton from "./components/UserButton"
    import {ValidationProvider, ValidationObserver} from "vee-validate"

    export default {
        components: {
            EpaymentFooter,
            CommonModal,
            UserButton,
            ValidationProvider,
            ValidationObserver,
        },
        metaInfo() {
            return {
                title: this.$t('epayment')
            }
        },
        data() {
            return {
                tabIndex: 0,
                epaymentToken: null,
                epaymentUser: {
                    number:null,
                    name:null,
                    surname:null,
                },
                debtsLoading:false,
                debts: {
                    payment_plans: {},
                    payments: {},
                    virtual_pos: {},
                },
                advancePaymentMethod: null,
                oneShotPaymentMethod: null,

                // PAYMENT
                paymentDataType: null,
                paymentDebtId: null,
                paymentType: null,
                paymentDebt: {},

                paymentFormMonthOptions: [],
                paymentFormYearOptions: [],
                paymentFormInstallmentOptions: [],

                paymentFormAmount: 0,
                paymentFormShowAmount: 0,
                paymentFormRemainingAmount: 0,
                paymentFormSumAmount: 0,
                paymentFormMaxAmount: 0,
                paymentFormPrice: 0,

                paymentFormCardHolderName: null,
                paymentFormCreditCardNumber: '',
                paymentFormCreditCardLogo: null,
                paymentFormExpiryMonth: null,
                paymentFormExpiryYear: null,
                paymentFormCvv: null,
                paymentFormBankCode: null,
                paymentFormInstallment: null,
                paymentFormExtraInstallment: null,
                paymentFormInstallmentDisabled: true,
                paymentFormCheck: '',

                paymentResult: '',
                paymentOrderId: null,
                paymentStatus: null,

                commonModalModeSize: 'lg',
                commonModalMode: '',

                bankCreditAdvanceAmount:null,
                bankCreditInstallment:null,
                bankCreditInterestRate:20
            }
        },
        created() {
            this.$store.dispatch('epayment/initAuth')
                .then(() => {
                    this.epaymentToken = this.$store.getters['epayment/getEpaymentToken'];
                    this.epaymentUser  = this.$store.getters['epayment/getEpaymentUser'];
                })
                .then(() => {
                    this.getDebts();
                });

            const year = new Date().getFullYear();
            for (var i = 0; i <= 12; i++) {
                if (i > 0) {
                    this.paymentFormMonthOptions.push(i);
                }
                if (i < 9) {
                    this.paymentFormYearOptions.push(year + i);
                }
            }
        },
        methods: {
            clearDebt() {
                this.paymentDataType = null;
                this.paymentDebtId = null;
                this.paymentType = null;
                this.paymentDebt = {};
                this.paymentOrderId = null;
            },
            modifyProgramStatusFields(paymentType) {
                return [
                    {key: 'payments_sum_amount', label: this.$t('amount_paid'), thClass: 'text-center', tdClass: 'text-center'},
                    {key: 'debt', label: this.$t('remaining_payment_amount'), thClass: 'text-center', tdClass: 'text-center'},
                    {key: 'total_price', label: this.$t('total_amount'), thClass: 'text-center', tdClass: 'text-center'}
                ]
            },
            modifyProgramStatusItems(paymentType, item) {
                return [
                    {
                        total_price: paymentType == 'advance' ? item.cash_price: item.price,
                        payments_sum_amount: item.payments_sum_amount,
                        debt: paymentType == 'advance' ? item.cash_debt : item.debt
                    }
                ]
            },
            modifyPosFields(paymentType) {
                const cols = [];
                cols.push({key: 'card_type', label: this.$t('card_type'), class: 'text-center align-middle'});
                if (paymentType != 'advance') {
                    cols.push({key: 'installments', label: this.$t('installment_count'), class: 'text-center align-middle'});
                }
                return cols;
            },
            modifyPosItems(paymentType, cards, currency) {
                const rows = [];
                if (cards && cards.length) {
                    cards.forEach(function (card) {
                        if (card.currencies.includes(currency)) {
                            if (paymentType == 'advance') {
                                rows.push({
                                    card_type: card.code,
                                    pay: card.code
                                })
                            }
                            else {
                                rows.push({
                                    card_type: card.code,
                                    installments: card.installments,
                                    extra_installment: card.extra_installment,
                                    pay: card.code
                                })
                            }
                        }
                    });
                }
                return rows;
            },
            getDebts() {
                this.debtsLoading = true;
                return EPaymentService.debts(this.epaymentToken)
                                      .then(response => {
                                          this.debts = response.data.data;
                                      })
                                      .then(() => {
                                          if (this.debts.virtual_pos) {
                                              this.debts.virtual_pos.forEach((elem) => {
                                                  if (elem.is_default) this.advancePaymentMethod = elem;
                                                  if (elem.is_default_for_one_shot) this.oneShotPaymentMethod = elem;
                                              })
                                          }
                                      })
                                      .catch(error => {
                                          this.$toast.error(this.$t('api.' + error.data.message));
                                      })
                                      .finally(() =>{
                                          this.debtsLoading = false;
                                      })
            },
            getDebt(uuid) {
                return EPaymentService.debt(this.epaymentToken, uuid)
                                      .then(response => {
                                          if (this.paymentDataType == 'payment_plan') {
                                              this.paymentDebt = response.data.data.payment_plan;
                                          }
                                          else if (this.paymentDataType == 'payment') {
                                              this.paymentDebt = response.data.data.payment;
                                          }
                                          else {
                                              this.paymentDebt = response.data.data.other_payment;
                                          }
                                      })
                                      .catch(error => {
                                          this.$toast.error(this.$t('api.' + error.data.message));
                                      });
            },
            getPosImage(code) {
                if (code == 'vakifbank') {
                    return require('@/assets/img/bank/world.svg');
                }
                else if (code == 'garanti') {
                    return require('@/assets/img/bank/bonus.svg');
                }
                else if (code == 'ziraatbank') {
                    return require('@/assets/img/bank/bankkart.svg');
                }
                else if (code == 'halkbank') {
                    return require('@/assets/img/bank/paraf.svg');
                }
                else if (code == 'isbank') {
                    return require('@/assets/img/bank/maximum.svg');
                }
                else if (code == 'akbank') {
                    return require('@/assets/img/bank/axess.svg');
                }
            },
            paymentFormAmountCalculate() {
                this.paymentFormShowAmount = this.paymentFormAmount
                this.paymentFormRemainingAmount = this.paymentFormMaxAmount - this.paymentFormAmount;
            },
            paymentSetVars(dataType, uuid, paymentType) {
                this.paymentDataType = dataType;
                this.paymentDebtId = uuid;
                this.paymentType = paymentType;

                this.$refs.payForm.reset();
                this.loadDept();
            },
            loadDept(){
                this.paymentDebt = {};
                this.getDebt(this.paymentDebtId)
                    .then(response => {
                        this.paymentFormAmount = 0;
                        this.paymentFormSumAmount = 0;
                        this.paymentFormMaxAmount = 0;

                        this.paymentFormCardHolderName = null;
                        this.paymentFormCreditCardNumber = '';
                        this.paymentFormExpiryMonth = null;
                        this.paymentFormExpiryYear = null;
                        this.paymentFormCvv = null;
                        this.paymentFormBankCode = null;
                        this.paymentFormInstallment = null;
                        this.paymentFormInstallmentDisabled = true;
                        this.paymentFormCheck = '';

                        if (this.paymentType == 'advance') {
                            this.paymentFormInstallmentOptions = [1];
                            this.paymentFormInstallment = 1;
                            this.paymentFormAmount = this.paymentDataType == 'payment_plan' ? this.paymentDebt.cash_debt:this.paymentDebt.amount;
                            this.paymentFormPrice = this.paymentDataType == 'payment_plan' ? this.paymentDebt.cash_price:this.paymentDebt.amount;
                            this.paymentFormSumAmount = this.paymentDebt.payments_sum_amount ? this.paymentDebt.payments_sum_amount:0;
                            this.paymentFormMaxAmount = this.paymentFormAmount
                            this.tabIndex = 1;
                        }
                        else if (this.paymentType == 'installment') {
                            this.paymentFormInstallmentOptions = [];
                            this.paymentFormPrice = this.paymentDebt.price
                            this.paymentFormAmount = this.paymentDebt.debt
                            this.paymentFormSumAmount = this.paymentDebt.payments_sum_amount ? this.paymentDebt.payments_sum_amount:0;
                            this.paymentFormMaxAmount = this.paymentDebt.debt
                            this.tabIndex = 1;
                        }
                        if((this.paymentDataType == 'payment' || this.paymentDataType == 'other_payment') && this.paymentDebt.is_paid){
                            this.paymentFormAmount=0;
                            this.paymentFormMaxAmount=0;
                            this.paymentFormSumAmount=this.paymentDebt.amount;
                        }
                        if(parseFloat(this.paymentFormAmount)===0 && parseFloat(this.paymentFormSumAmount)>0){
                            setTimeout(()=>{
                                this.clearDebt();
                                this.getDebts();
                            }, 10000);
                        }
                    })
                    .finally(()=> {
                        this.paymentFormAmountCalculate();
                    })
            },
            paymentFormInstallmentCustomLabel(value) {
                if (value == 1) {
                    return this.$t('advance_payment');
                } else {
                    return value +(this.paymentFormExtraInstallment>0?'+'+this.paymentFormExtraInstallment:'')+' ' + this.$t('installment');
                }
            },
            async pay() {
                const isValid = await this.$refs.payForm.validate();
                if (isValid) {
                    const creditCardNumber = this.paymentFormCreditCardNumber;
                    const formData = {
                        bank_code: this.paymentFormBankCode,
                        amount: this.paymentFormAmount,
                        card_holder_name: this.paymentFormCardHolderName,
                        credit_card_number: creditCardNumber.replace(/[^0-9]/g, ""),
                        expiry_month: this.paymentFormExpiryMonth,
                        expiry_year: this.paymentFormExpiryYear,
                        installment: this.paymentFormInstallment,
                        cvv: this.paymentFormCvv,
                        is_cash: this.paymentType == 'advance' ? 1 : 0,
                    };
                    return EPaymentService.pay(this.epaymentToken, this.paymentDebtId, formData)
                                          .then(response => {
                                              const data = response.data.data;

                                              this.paymentResult = data.html;
                                              this.paymentOrderId = data.order_id;

                                              this.$refs.threeDPayModal.$refs.commonModal.show()
                                          })
                                          .catch(error => {
                                              if (error.status == 422) {
                                                  if (error.data.errors.amount) {
                                                      this.$refs.payForm.errors.payment_total.push(error.data.errors.amount);
                                                  }
                                                  if (error.data.errors.card_holder_name) {
                                                      this.$refs.payForm.errors.card_owner.push(error.data.errors.card_holder_name);
                                                  }
                                                  if (error.data.errors.credit_card_number) {
                                                      this.$refs.payForm.errors.card_number.push(error.data.errors.credit_card_number);
                                                  }
                                                  if (error.data.errors.expiry_month) {
                                                      this.$refs.payForm.errors.expiry_month.push(error.data.errors.expiry_month);
                                                  }
                                                  if (error.data.errors.expiry_year) {
                                                      this.$refs.payForm.errors.expiry_year.push(error.data.errors.expiry_year);
                                                  }
                                                  if (error.data.errors.installment) {
                                                      this.$refs.payForm.errors.installment.push(error.data.errors.installment);
                                                  }
                                                  if (error.data.errors.cvv) {
                                                      this.$refs.payForm.errors.cvc.push(error.data.errors.cvv);
                                                  }
                                              }
                                              else if (error.status == 406) {
                                                  this.$toast.error(this.$t('api.' + error.data.message));
                                              }
                                          });
                }
            },
            showModal(mode) {
                this.commonModalMode = mode;
                this.$refs.paymentModal.$refs.commonModal.show()
            },
            closedThreeDPayModal() {
                if(this.paymentOrderId){
                    return EPaymentService.paymentStatus(this.epaymentToken, this.paymentOrderId)
                                          .then(response => {
                                              this.paymentStatus = response.data.data.status;
                                              this.paymentStatusMessage = response.data.data.message;
                                              if(this.paymentStatus=='approved'){
                                                  this.$refs.payForm.reset();
                                                  this.loadDept();
                                                  this.$toast.success(this.$t('your_payment_transaction_has_been_successfully_completed'));
                                              }
                                              else if(this.paymentStatus=='declined'){
                                                  this.$toast.error(this.$t('something_went_wrong')+' '+this.$t('please_try_again'));
                                              }
                                          })
                                          .catch(error => {
                                              this.paymentStatus = 'error';
                                          });
                }
            },
            logout() {
                this.$router.push('epayment/logout')
            },
            calculateBankCredit(plan){
                if(!this.bankCreditInstallment){
                    return;
                }
                let advanceAmount = parseInt(this.bankCreditAdvanceAmount);
                if(advanceAmount<0){
                    advanceAmount=0;
                }
                let price = Math.round((plan.price-advanceAmount) * (100+this.bankCreditInterestRate) / 100 * 100)/100;
                let installmentAmount = Math.round(price / parseInt(this.bankCreditInstallment) * 100) / 100;

                let msg = 'Bugünkü oranlarla simülasyon sonucu, ';
                if(advanceAmount>0){
                    msg+=this.formatNumber(advanceAmount)+' TL peşinat + '
                }
                msg+=this.formatNumber(price)+ '('+ this.formatNumber(installmentAmount)+'x'+this.bankCreditInstallment+ ') TL`dir.';
                this.$swal.fire({
                    icon: null,
                    text: msg,
                    confirmButtonText: this.$t('ok'),
                })
            },
            downloadReceipt(id){
                EPaymentService.downloadReceipt(this.epaymentToken, id)
                    .then(response=>{
                        this._downloadFile(response, id+'.pdf');
                    })
                    .catch(e=>{
                        this.showErrors(e)
                    });
            }
        },
        watch: {
            paymentFormCreditCardNumber(value, oldValue) {
                if (value!='' && value.length > 6) {
                    let value6Char = value.substring(0, 7).replaceAll(' ','');
                    let oldValue6Char = oldValue.substring(0, 7).replaceAll(' ','');
                    if(value6Char != oldValue6Char) {
                        this.paymentFormCreditCardLogo = null;
                        if(value6Char.length!=6){
                            return;
                        }
                        return EPaymentService.virtualPosBin(this.epaymentToken, value6Char)
                                              .then(response => {
                                                  var bank = response.data.data;

                                                  this.paymentFormBankCode = null;
                                                  this.paymentFormInstallmentOptions = null;
                                                  this.paymentFormExtraInstallment = null;

                                                  // Pos Check
                                                  this.debts.virtual_pos.forEach((elem) => {
                                                      if(elem.code==bank){
                                                          this.paymentFormBankCode = elem.code;

                                                          if(this.paymentType=='installment'){
                                                              let installment = elem.installments;
                                                              if(installment && installment.length==1){
                                                                  this.paymentFormInstallment = installment[0];
                                                                  if(elem.extra_installment>0){
                                                                      this.paymentFormExtraInstallment=elem.extra_installment;
                                                                  }
                                                              }
                                                          }
                                                          else{
                                                              let installment = [1];
                                                              this.paymentFormInstallment = installment[0];
                                                          }
                                                          this.paymentFormInstallmentOptions = this.paymentType=='installment' ? elem.installments:[1];
                                                      }
                                                  })

                                                  // Pos var/yok ise?
                                                  if (this.paymentFormBankCode) {
                                                      this.paymentFormCreditCardLogo = this.paymentFormBankCode;
                                                      if (this.paymentType == 'advance') {
                                                          if(this.oneShotPaymentMethod){
                                                              this.paymentFormBankCode = this.oneShotPaymentMethod.code;
                                                          }
                                                          this.paymentFormInstallment = 1;
                                                      }

                                                      this.paymentFormInstallmentDisabled = false;
                                                  }
                                                  else {
                                                      if (this.paymentType == 'advance') {
                                                          if(this.advancePaymentMethod){
                                                              this.paymentFormBankCode = this.advancePaymentMethod.code;
                                                              this.paymentFormInstallment = 1;
                                                              this.paymentFormInstallmentDisabled = false;
                                                          }
                                                          else {
                                                              this.paymentFormInstallmentDisabled = true;
                                                          }
                                                      }
                                                      else if (this.paymentType == 'installment') {
                                                          this.$toast.error(this.$t('no_installment_message'));
                                                          this.paymentFormInstallmentDisabled = true;
                                                      }
                                                  }
                                              })
                                              .catch(error => {
                                                  this.paymentFormInstallmentDisabled = true;
                                                  if (error.status == 406) {
                                                      if (error.data.message == 'BIN_NOT_FOUND') {
                                                          if (this.paymentType == 'advance' && this.advancePaymentMethod) {
                                                              this.paymentFormBankCode = this.advancePaymentMethod.code;
                                                              this.paymentFormInstallment = 1;
                                                              this.paymentFormInstallmentDisabled = false;
                                                          }
                                                          else if (this.paymentType == 'installment') {
                                                              this.$toast.error(this.$t('no_installment_message'));
                                                          }
                                                      }
                                                      else if (this.paymentType == 'installment') {
                                                          this.$toast.error(this.$t('no_installment_message'));
                                                      }
                                                  }
                                              })
                    }
                }
                else {
                    this.paymentFormBankCode = null;
                    this.paymentFormInstallmentOptions = [];
                    this.paymentFormInstallment = null;
                    this.paymentFormInstallmentDisabled = true;
                    this.paymentFormCreditCardLogo = null;
                }
            }
        }
    }
</script>
